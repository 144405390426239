import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import Text from 'common/components/Text';
import Heading from 'common/components/Heading';
import BlogPost from 'common/components/BlogPost';
import Box from 'common/components/Box';
import Image from 'common/components/Image';
import Container from 'common/components/UI/Container';
import SectionWrapper, {
  SectionHeader,
  FeatureWrapper,
  ImageWrapper
} from './workSection.style';

const WorkSection = ({ row, col }) => {
  const data = useStaticQuery(graphql`
    query {
      charityJson {
        workData {
          title
          imageOne{
            publicURL
          }
          imageTwo{
            publicURL
          }
          imageThree{
            publicURL
          }
          slogan
          features {
            id
            icon {
              publicURL
            }
            title
            description
          }
        }
      }
    }
  `);

  console.log('this is data', data);

  const { title, slogan, features, imageOne, imageTwo, imageThree } = data.charityJson.workData;
  return (
    <SectionWrapper id="work">
      <Container width="1260px">
        <SectionHeader>
        <FeatureWrapper>
          <ImageWrapper>
            <Image src={imageOne.publicURL} alt="Charity Landing" />
          </ImageWrapper>

          <ImageWrapper>
            <Image src={imageTwo.publicURL} alt="Charity Landing" />
          </ImageWrapper>

          <ImageWrapper>
            <Image src={imageThree.publicURL} alt="Charity Landing" />
          </ImageWrapper>
        </FeatureWrapper>
        </SectionHeader>
      </Container>
    </SectionWrapper>
  );
};


// PromotionBlock style props
WorkSection.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
};

// WorkSection default style
WorkSection.defaultProps = {
  // PromotionBlock row default style
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
  },
  // PromotionBlock col default style
  col: {
    width: ['100%', '50%', '50%'],
    pl: '0px',
    pr: '0px',
    mb: '30px',
  },
};

export default WorkSection;
