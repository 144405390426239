import React, { Fragment } from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { Icon } from 'react-icons-kit';
import { chevronRight } from 'react-icons-kit/feather/chevronRight';
import Image from 'gatsby-image';
import Text from 'common/components/Text';
import Heading from 'common/components/Heading';
import GlideCarousel from 'common/components/GlideCarousel';
import GlideSlide from 'common/components/GlideCarousel/glideSlide';
import LeftBar from './leftBar';
import BannerWrapper, {
  ContentWrapper,
  TextArea,
  ImageArea,
  HighlightedText,
} from './bannerSection.style';

const BannerSection = () => {
  const glideOptions = {
    type: 'carousel',
    perView: 1,
    gap: 0,
  };

  const data = useStaticQuery(graphql`
    query {
      charityJson {
        bannerSlides {
          id
          thumb_url {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `);

  return (
    <BannerWrapper>
      <LeftBar text="" offset={81} sectionId="#feature" />
      <ContentWrapper>
        <TextArea>
          <Heading
            content="Yuni and Matt’s Wedding Fundraiser"
          />
          <Heading
            as="h4"
            content="Yuni and Matt were married on December 31st, 2020"
          />
          <Text
            content="With our shared love of restoring the beauty of the ocean we are asking for donations to the Misool Foundation rather than receive wedding gifts. We have chosen Misool Foundation as its work supports the conservation of some of our favourite ecosystems and animals and supports local communities in Indonesia by building capacity and creating jobs."
          />

          <Text
            content="All donations are fully tax deductable."
          />

          <a target="_blank" href="https://misoolfoundation.org" className="learn__more-btn">
            <span className="hyphen" />
            <span className="btn_text">Learn More</span>
          </a>
        </TextArea>
        <ImageArea>
                  <img
                    height="100%"
                    style={{height:'100%', width:'100%'}}
                    src="https://images.squarespace-cdn.com/content/v1/588f4f236a4963c41010a7d9/1485859493363-ARRMZ2F98KI70C18TFKE/ke17ZwdGBToddI8pDm48kDHPSfPanjkWqhH6pl6g5ph7gQa3H78H3Y0txjaiv_0fDoOvxcdMmMKkDsyUqMSsMWxHk725yiiHCCLfrh8O1z4YTzHvnKhyp6Da-NYroOW3ZGjoBKy3azqku80C789l0mwONMR1ELp49Lyc52iWr5dNb1QJw9casjKdtTg1_-y4jz4ptJBmI9gQmbjSQnNGng/Misool.above.Bruno.Hopff.JPG"
                    alt="Misool"
                    className="slide_image"
                  />

        </ImageArea>
      </ContentWrapper>
    </BannerWrapper>
  );
};

export default BannerSection;
