import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import Box from 'common/components/Box';
import Image from 'common/components/Image';
import Text from 'common/components/Text';
import Heading from 'common/components/Heading';
import Container from 'common/components/UI/Container';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import heartImage from 'common/assets/image/charity/heart-red.png';
import NavbarWrapper, { MenuWrapper, Button } from '../Navbar/navbar.style';

import BlockWrapper, {
  ContentWrapper,
  List,
  Item,
  ImageWrapper,
} from './promotionBlock.style';

const runSite = () => {
  window.location.href= 'https://donate.wildaid.org/give/347498/#!/donation/checkout'
}


const PromotionBlock = ({ row, col }) => {
  const data = useStaticQuery(graphql`
    query {
      charityJson {
        promotionData {
          image {
            publicURL
          }
          slogan
          title
          text1
          text2
          text3
          text4
        }
      }
    }
  `);

  const {
    slogan,
    title,
    text1,
    text2,
    text3,
    text4,
    image,
  } = data.charityJson.promotionData;

  return (
    <BlockWrapper id="ourCommunity">
      <Container width="1260px">
        <Box className="row" {...row}>
          <Box className="col" {...col} style={{paddingLeft:'20px'}}>
            <ContentWrapper>
              <Heading as="h5" content={slogan} />
              <Heading content={title} />
              <Text content={text1} />
              <Text content={text2} />
              <Text content={text3} />
              <Text content={text4} />

              <a href="https://www.misoolfoundation.org/whoweare" style={{float:'left', marginRight:'15px', marginBottom:'15px'}}  className="learn__more-btn">
                <span className="hyphen" />
                <span className="btn_text">Learn More </span>
              </a>
            <Button onClick={()=>runSite()}>
                <span className="text">Donate Now</span>
                <Image src={heartImage} alt="Charity Landing" />
            </Button>
            </ContentWrapper>
          </Box>
          <Box className="col" {...col}>
            <ImageWrapper>
              <Image src={image.publicURL} alt="Charity Landing" />
            </ImageWrapper>
          </Box>
        </Box>
        <div className="video-container">
        <iframe className="video" width="100%" height="auto" src="https://www.youtube.com/embed/a0SOstcmz0s" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
      </Container>

    </BlockWrapper>
  );
};

// PromotionBlock style props
PromotionBlock.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
};

// PromotionBlock default style
PromotionBlock.defaultProps = {
  // PromotionBlock row default style
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
  },
  // PromotionBlock col default style
  col: {
    width: ['100%', '50%', '50%'],
    pl: '0px',
    pr: '0px',
    mb: '30px',
  },
};

export default PromotionBlock;
