import React from 'react';
import { Link } from 'gatsby';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import Image from 'common/components/Image';
import Container from 'common/components/UI/Container';
import NavbarWrapper, { MenuWrapper, Button } from './navbar.style';

import logoImage from 'common/assets/image/charity/logo.svg';
import heartImage from 'common/assets/image/charity/heart-red.png';

const runSite = () => {
  window.location.href= 'https://donate.wildaid.org/give/347498/#!/donation/checkout'
}

const Navbar = () => {
  return (
    <NavbarWrapper className="navbar">
      <Container fullWidth={true}>
        <Link className="logo" to="/charity">
          <Image src={logoImage} alt="Reef Hunters" />
        </Link>
        <MenuWrapper>
          <h4 style={{color:"#fff", marginRight:'50px'}}>
            Help us help Misool
          </h4>
          <Button  onClick={()=>runSite()}>

              <span className="text">Donate Now</span>
              <Image src={heartImage} alt="Charity Landing" />

          </Button>
        </MenuWrapper>
      </Container>
    </NavbarWrapper>
  );
};

export default Navbar;
